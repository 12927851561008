
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";

@Component({
  inheritAttrs: false
})
export default class InputPhone extends Vue {
  countryNum: string = "1";
  phone: string = "";
  @Prop({ default: "" })
  height: string;
  created() {}
}
